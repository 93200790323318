<template>
  <div>

  </div>
</template>

<script>
  export default {
    name: 'MemberIndex',
    data () {
      return {
        title: '客户中心'
      }
    },
    beforeCreate () {
      this.$router.push({name: 'projectList'})
    }
  }
</script>

<style scoped>
  div {
    font-size: 0;
  }

  span, label {
    display: inline-block;
    line-height: 40px;
    font-size: 14px;
  }

  label {
    width: 100px;
    text-align: right;
    color: #666666;
  }

  div > span {
    color: #333333;
  }
</style>